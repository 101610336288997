import { InMemoryCache, makeVar } from "@apollo/client"

const globalWindow = typeof window !== "undefined"
const localStorageLocale = globalWindow ? localStorage.getItem("locale") : "fr"
export const cacheLocale = makeVar(localStorageLocale || "fr")

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        cacheLocale: {
          read() {
            return cacheLocale()
          },
        },
      },
    },
  },
})

export default cache
