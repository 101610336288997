import cls from "classnames"
import { overrideTailwindClasses } from "tailwind-override"
import { ClassName } from "~types"

const Cls = (...args: ClassName[]) => {
  const className = cls(...args)
  return overrideTailwindClasses(className)
}

export default Cls
