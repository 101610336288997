import React from 'react'
import { RootElement, PageElement } from '~components'
import '@fontsource/nunito-sans/200.css'
import '@fontsource/nunito-sans/200-italic.css'
import '@fontsource/nunito-sans/300.css'
import '@fontsource/nunito-sans/300-italic.css'
import '@fontsource/nunito-sans/400.css'
import '@fontsource/nunito-sans/400-italic.css'
import '@fontsource/nunito-sans/600.css'
import '@fontsource/nunito-sans/600-italic.css'
import '@fontsource/nunito-sans/700.css'
import '@fontsource/nunito-sans/700-italic.css'
import '@fontsource/nunito-sans/800.css'
import '@fontsource/nunito-sans/800-italic.css'
import '@fontsource/nunito-sans/900.css'
import '@fontsource/nunito-sans/900-italic.css'
import '~styles/app.css'

export const wrapPageElement = props => <PageElement {...props} />
export const wrapRootElement = props => <RootElement {...props} />
