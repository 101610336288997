import dayjs, {
  Dayjs,
  OpUnitType,
  QUnitType,
  UnitTypeLong,
  UnitTypeLongPlural,
} from "dayjs"
import duration from "dayjs/plugin/duration"
import minMax from "dayjs/plugin/minMax"
import isBetween from "dayjs/plugin/isBetween"

dayjs.extend(duration)
dayjs.extend(minMax)
dayjs.extend(isBetween)

type DurationUnit = Exclude<UnitTypeLongPlural, "dates"> | "weeks"
type InclusionUnit = "()" | "[]" | "[)" | "(]"

export default {
  week(date: Dayjs = dayjs()) {
    const monday = date.startOf("week")

    return new Array(7).fill(0).map((_, index) => {
      let day = index + 1

      if (index === 6) {
        day = 0
      }

      return monday.day(day)
    })
  },
  calendar(date: Dayjs = dayjs()) {
    date = date.startOf("month")
    const daysInMonth = date.daysInMonth()
    /* Starts from 0 - Sunday */
    const monthStartWeekDay = date.day() + 1

    const days = 7
    const weeks = 6
    /* Grid */
    const calendarDays = weeks * days
    /* Days of prev month */
    const prevMonthDaysOffset = days - monthStartWeekDay
    /* Days of next month */
    const nextMonthDaysOffset = calendarDays - prevMonthDaysOffset - daysInMonth

    /* Prev month days */
    const prevMonthDays = new Array(prevMonthDaysOffset)
      .fill(0)
      .map((_, index) => date.subtract(++index, "day"))
      .reverse()

    /* Prev month days */
    const nextMonthDays = new Array(nextMonthDaysOffset)
      .fill(0)
      .map((_, index) => date.endOf("month").add(++index, "day"))

    const currentMontDays = new Array(daysInMonth)
      .fill(0)
      .map((_, index) => date.date(++index))

    return [...prevMonthDays, ...currentMontDays, ...nextMonthDays]
  },
  diff(
    firstDate: Dayjs,
    secondDate: Dayjs,
    unit: QUnitType | OpUnitType = "milliseconds"
  ) {
    return firstDate.diff(secondDate, unit)
  },
  duration(
    firstDate: Dayjs,
    secondDate: Dayjs,
    unit: DurationUnit = "milliseconds"
  ) {
    return dayjs.duration(firstDate.diff(secondDate))[unit]()
  },
  min(...args: Dayjs[]) {
    return dayjs.min(...args)
  },
  max(...args: Dayjs[]) {
    return dayjs.max(...args)
  },
  isBetweenBy(
    date: Dayjs,
    firstDate: Dayjs,
    secondDate: Dayjs,
    unit: OpUnitType = "milliseconds",
    inclusion: InclusionUnit = "()"
  ) {
    return date.isBetween(firstDate, secondDate, unit, inclusion)
  },
  isSameBy(
    firstDate: Dayjs,
    secondDate: Dayjs,
    units: UnitTypeLong[] = ["millisecond"]
  ) {
    return units.every(unit => firstDate[unit]() === secondDate[unit]())
  },
  isSameDay(firstDate: Dayjs, secondDate: Dayjs) {
    return this.isSameBy(firstDate, secondDate, ["date", "month", "year"])
  },
}
