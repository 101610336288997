import { PageProps } from "gatsby"
import React, { memo } from "react"
import { Footer, Header, Navigation } from "~sections"
import { Children } from "~types"
import { useWindowSize } from "~hooks"


export interface PageElementProps {
  element: Children
  props: PageProps
}

const PageElement: React.FC<PageElementProps> = props => {
  const { element: page, props: pageProps } = props
  const { toRender: isMobile } = useWindowSize(["mobile",'landscape'])


  return (
    <div className="overflow-hidden">
      <main className={""}>
        <div className={"fixed"}>
          {!isMobile && (<Navigation />)}
        </div>
        <div className="flex">
          <Header />
          {page}
        </div>
      </main>
      <Footer />
    </div>
  )
}

export default memo(PageElement)
