import loadable from "@loadable/component"

export const Navigation = loadable(() => import("./Navigation"))
export const NavigationMobile = loadable(() => import("./NavigationMobile"))
export const Header = loadable(() => import("./Header"))
export const FixedHeader = loadable(() => import("./FixedHeader"))
export const Footer = loadable(() => import("./Footer"))

export * from "./Navigation"
export * from "./Header"
export * from "./FixedHeader"
export * from "./Footer"
export * from "./NavigationMobile"
