import { Link, useStaticQuery, graphql } from "gatsby"
import React, { memo, useEffect, useState } from "react"
import { useLocation } from "@reach/router"
import cls from "classnames"
import { NavigationItemType } from "~types"
import { useSiteMetadata, useWindowSize, useIntl } from "~hooks"
import { Disclosure } from "~ux"
import { LanguageSwitcher } from "~ui"

export interface NavigationProps {
}

const Navigation: React.FC<NavigationProps> = props => {
  const {} = props

  const [intl] = useIntl()
  const { pathname } = useLocation()
  const { navigation } = useSiteMetadata()
  const { toRender: isMobile } = useWindowSize(["mobile"])
  const { toRender: isLandscape } = useWindowSize(["landscape"])

  const [isAvocats, setIsAvocats] = useState<boolean>(false)
  const [isCompetences, setCompetences] = useState<boolean>(false)
  const [isContact, setContact] = useState<boolean>(false)

  useEffect(() => {
    setIsAvocats(pathname.split("/").includes("equipe"))
    setCompetences(pathname.split("/").includes("competences"))
    setContact(pathname.split("/").includes("contact"))
  }, [pathname])

  const pathArray = pathname.split("/")
  const currentRoute = pathArray[pathArray.length - 1]

  const expretises = useStaticQuery(
    graphql`
        query {
            expretises: allMarkdownRemark {
                edges {
                    node {
                        fields {
                            slug
                        }
                        frontmatter {
                            label
                            priority
                            advocates {
                                position
                            }
                        }
                    }
                }
            }
        }
    `
  ).expretises.edges

  const expretisesLinks = expretises
    .map(edge => {
      const {
        node: {
          fields: { slug },
          frontmatter: { label, icon, priority }
        }
      } = edge

      return {
        path: slug,
        label,
        icon,
        priority
      }
    })
    .filter(item => item.path.includes("competences"))
    .sort((a, b) => {
      return a.priority - b.priority
    })

  const advocatesLinks = expretises
    .map(edge => {
      const {
        node: {
          fields: { slug },
          frontmatter: { label, icon, advocates, priority }
        }
      } = edge

      return {
        path: slug,
        position: advocates && advocates[0]?.position,
        label,
        icon,
        priority
      }
    })
    .filter(item => !item.path.includes("competences"))

  const firstAdvocateLink = advocatesLinks[1].path

  const renderCompetences = (item: NavigationItemType) => {
    return (
      <div key={item.path}>
        <Disclosure
          onLabelClick={() => {
            setIsAvocats(false)
            setCompetences(!isCompetences)
          }}
          panelLabel={item.label}
          links={expretisesLinks}
          panelPath={"/accueil"}
          show={isCompetences}
          currentRoute={currentRoute}
          name="competences"
        />
      </div>
    )
  }

  const renderAdvocates = (item: NavigationItemType) => {
    return (
      <div key={item.path}>
        <Disclosure
          onLabelClick={() => {
            setCompetences(false)
            setIsAvocats(!isAvocats)
          }}
          panelLabel={item.label}
          panelPath={firstAdvocateLink}
          links={advocatesLinks}
          show={isAvocats}
          currentRoute={currentRoute}
          name="advocates"
        />
      </div>
    )
  }

  if (isMobile) {
    return null
  }

  return (
    <div
      className={`container mt-[25vh] z-30 max-w-[450px] overflow-y-scroll h-[88vh]`}
    >
      {navigation.map(item => {
        const isCompetences = item.path === "/competences"
        const isAvocate = item.path === "/equipe"


        if (isCompetences) return renderCompetences(item)
        else if (isAvocate) return renderAdvocates(item)

        return (
          <div key={item.label}>
            {!isLandscape && (
              <Link
                to={item.path}
                className={cls(
                  "group font-bold text-2xl text-text-black transition-colors",
                  isContact ? "hover:text-tw-gray-300" : "hover:text-[#cb4405] "
                )}
              >
                {intl(item.label)}
              </Link>
            )}
          </div>
        )
      })}
      {!isMobile && !isLandscape && <LanguageSwitcher />}
    </div>
  )
}

export default memo(Navigation)
