import React, { memo } from "react"
import { useReactiveVar } from "@apollo/client"
import { IntlProvider as Provider } from "react-intl"
import { cacheLocale } from "~cache"
import { Children } from "~types"
import frMessages from "../../locales/fr.json"
import enMessages from "../../locales/en.json"

type Locale = "fr" | "en"

interface IntlProviderProps {
  children: Children
}

const messages = {
  fr: frMessages,
  en: enMessages,
}

const IntlProvider: React.FC<IntlProviderProps> = props => {
  const { children } = props
  const locale = useReactiveVar(cacheLocale) as Locale

  return (
    <Provider messages={messages[locale]} locale="fr" defaultLocale="fr">
      {children}
    </Provider>
  )
}

export default memo(IntlProvider)
