import loadable from "@loadable/component"

export const Panel = loadable(() => import("./Panel"))
export const Button = loadable(() => import("./Button/Button"))
export const Checkbox = loadable(() => import("./Checkbox/Checkbox"))
export const Radio = loadable(() => import("./Radio/Radio"))
export const Switch = loadable(() => import("./Switch"))
// export const Radio = loadable(() => import('./Radio'))
export const TextInput = loadable(() => import("./TextInput"))
export const TextArea = loadable(() => import("./TextArea"))
export const Select = loadable(() => import("./Select"))
export const MultiSelect = loadable(() => import("./MultiSelect"))
export const Autocomplete = loadable(() => import("./Autocomplete"))
export const LanguageSwitcher = loadable(() => import("./LanguageSwitcher"))
export const Table = loadable(() => import("./Table/Table"))
export const DatePicker = loadable(() => import("./DatePicker/DatePicker"))
export const DateTimePicker = loadable(
  () => import("./DatePicker/DateTimePicker")
)
export const DateRangePicker = loadable(
  () => import("./DatePicker/DateRangePicker")
)

export * from "./Panel"
export * from "./Button/Button"
export * from "./Checkbox/Checkbox"
export * from "./Radio/Radio"
export * from "./Switch"
// export * from './Radio'
export * from "./TextInput"
export * from "./TextArea"
export * from "./Select"
export * from "./MultiSelect"
export * from "./Autocomplete"
export * from "./LanguageSwitcher"
export * from "./Table/Table"
export * from "./DatePicker/DatePicker"
export * from "./DatePicker/DateTimePicker"
export * from "./DatePicker/DateRangePicker"
