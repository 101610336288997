import React, { memo, useEffect } from 'react'
import ReactDOM from 'react-dom'
import cls from 'classnames'
import { Children, ClassName } from '~types'

export interface FixedHeaderProps {
  className?: ClassName
  children: Children
  noPortal?: boolean
}

const FixedHeader: React.FC<FixedHeaderProps> = props => {
  const { className, children, noPortal } = props

  if (noPortal) {
    useEffect(() => {
      const header = document.querySelector('#header')

      if (header) {
        header.classList.add('hidden')
      }
    }, [])

    return (
      <header id={'header'} className={cls(className, 'relative z-2 flex-shrink-0 px-2 py-28')}>
        {children}
      </header>
    )
  }

  return ReactDOM.createPortal(children, document.querySelector('#header')!)
}

export default memo(FixedHeader)
