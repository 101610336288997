import React, { FC, memo } from "react"
import cls from "classnames"
import { ClassName } from "~types"

interface IBurgerMenu {
  showBurger: boolean
  onClick: () => void
  className?: ClassName
}

const BurgerMenu: FC<IBurgerMenu> = props => {
  const { className, showBurger, onClick } = props

  return (
    (!showBurger && (
      <svg
        onClick={onClick}
        className={cls("fill-current", className)}
        width="59"
        height="44"
        viewBox="0 0 59 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0H59V8H0V0Z" fill="#080000" />
        <path d="M0 18H59V26H0V18Z" fill="#080000" />
        <path d="M0 36H59V44H0V36Z" fill="#080000" />
      </svg>
    )) || (
      <svg
        onClick={onClick}
        className={cls("fill-current", className)}
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.65692 0.000604153L47.3762 41.7199L41.7194 47.3768L6.10948e-05 5.65746L5.65692 0.000604153Z"
          fill="#080000"
        />
        <path
          d="M47.3762 5.65685L5.65685 47.3762L0 41.7193L41.7193 0L47.3762 5.65685Z"
          fill="#080000"
        />
      </svg>
    )
  )
}

export default memo(BurgerMenu)
