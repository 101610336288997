import loadable from '@loadable/component'

export * from './layouts/EXPORT'
export * from './providers/EXPORT'
export * from './sections/EXPORT'
export * from './seo/EXPORT'

export const RootElement = loadable(() => import('./RootElement'))
export const PageElement = loadable(() => import('./PageElement'))

export * from './RootElement'
export * from './PageElement'
