import React, { memo } from 'react'
import LoaderComponent from 'react-loader-spinner'
import cls from 'classnames'
import { ClassName } from '~types'
import { enumValues, Sb } from '~utils'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import './Loader.css'

export enum LoaderTypes {
  Audio = 'Audio',
  BallTriangle = 'BallTriangle',
  Bars = 'Bars',
  Circles = 'Circles',
  Grid = 'Grid',
  Hearts = 'Hearts',
  Oval = 'Oval',
  Puff = 'Puff',
  Rings = 'Rings',
  TailSpin = 'TailSpin',
  ThreeDots = 'ThreeDots',
  Watch = 'Watch',
  RevolvingDot = 'RevolvingDot',
  Triangle = 'Triangle',
  Plane = 'Plane',
  MutatingDots = 'MutatingDots',
  CradleLoader = 'CradleLoader'
}

export interface LoaderProps {
  className?: ClassName
  type?: keyof typeof LoaderTypes
}

const Loader: React.FC<LoaderProps> = props => {
  const { className, type = 'Oval', ...otherProps } = props

  return (
    <div className={cls('loader', className)} data-testid={'loader'}>
      <LoaderComponent color={'#F92D7A'} width={'100%'} height={'100%'} type={type} {...otherProps} />
    </div>
  )
}

const MemoLoader = memo(Loader)

MemoLoader.displayName = 'Ux/Loader'
MemoLoader.defaultProps = {
  type: 'Oval'
}

MemoLoader.storybook = {
  className: Sb({
    name: 'Class',
    description: 'Set loader class'
  }),
  type: Sb({
    name: 'Type',
    description: 'Set loader type',
    type: 'enum',
    options: enumValues(LoaderTypes) as string[]
  })
}

export default MemoLoader
