import React, { memo } from "react"
import cls from "classnames"
import { IconProps } from "~svg"

const MessageIcon: React.FC<IconProps> = props => {
  const { className, ...otherProps } = props

  return (
    <svg
      className={cls("fill-current", className)}
      width="480" height="384" viewBox="0 0 480 384" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 0V384H480V0H0ZM220.8 229.6C226.35 233.729 233.083 235.959 240 235.959C246.917 235.959 253.65 233.729 259.2 229.6L282.667 212L448 336V352H32V336L197.333 212L220.8 229.6ZM240 204L32 48V32H448V48L240 204ZM32 88L170.667 192L32 296V88ZM448 296L309.333 192L448 88V296Z"
        fill="" />
    </svg>


  )
}

export default memo(MessageIcon)
