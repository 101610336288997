import React, { memo, forwardRef, Fragment, useState } from "react"
import cls from "classnames"
import { Link } from "gatsby"
import {
  Disclosure as DisclosureComponent,
  Transition,
} from "@headlessui/react"
import { ClassName } from "~types"
import { useWindowSize, useIntl } from "~hooks"

interface link {
  icon?: string
  path: string
  label: string
  position: string
  priority: number
}

export interface DisclosureProps {
  className?: ClassName
  show?: boolean
  onLabelClick?: () => void
  panelLabel: string
  panelPath: string
  links: link[]
  currentRoute?: string
  name: string
  onBurgerClose: () => void
}

const Disclosure: React.FC<DisclosureProps> = forwardRef<
  HTMLInputElement,
  DisclosureProps
>((props, ref) => {
  const {
    className,
    show,
    panelPath,
    panelLabel,
    links,
    onLabelClick,
    currentRoute,
    name,
    onBurgerClose,
  } = props


  const [intl] = useIntl()
  const { toRender: isLandscape } = useWindowSize(["landscape"])

  const [openCategory, setOpenCategory] = useState<
    "AVOCAT" | "EXPERT" | "ASSISTANT" | null
  >(null)
  const isContactPage = currentRoute === "contact"

  const mapLinks = (link: link) => {
    const linkPathArray = link.path.split("/")
    const currentLink = linkPathArray[linkPathArray.length - 1]
    return (
      <div key={link.label} onClick={onBurgerClose}>
        <Link
          to={link.path}
          className={cls(
            `items-center group font-bold text-[0.875rem] ${
              currentLink === currentRoute
                ? "text-[#cb4405]"
                : "text-text-black"
            }  transition-colors`,
            !isContactPage ? "hover:text-[#cb4405]" : "hover:text-tw-gray-300"
          )}
        >
          {intl(link.label).toUpperCase()}
        </Link>
      </div>
    )
  }

  const renderEquipeBlock = (category: "AVOCAT" | "EXPERT" | "ASSISTANT") => {
    return (
      <>
        <p
          onClick={() => {
            setOpenCategory(category)
            if (category === openCategory) setOpenCategory(null)
          }}
          className={cls(
            "cursor-pointer select-none text-text-black ",
            !isContactPage ? "hover:text-[#cb4405]" : "hover:text-tw-gray-300"
          )}
        >
          {intl(`Sublink.${category}`)}
        </p>
        {category === openCategory && (
          <div onClick={onBurgerClose}>
            {links
              ?.filter(link => link.position === category)
              .sort((a, b) => a.priority - b.priority)
              .map(mapLinks)}
          </div>
        )}
      </>
    )
  }

  const RenderLinks = () => {
    return name === "advocates" ? (
      <>
        {renderEquipeBlock("AVOCAT")}
        {renderEquipeBlock("EXPERT")}
        {renderEquipeBlock("ASSISTANT")}
      </>
    ) : (
      links?.map(mapLinks)
    )
  }

  return (
    <DisclosureComponent as={"div"} className={cls(className)} ref={ref}>
      {({ open }) => {
        return (
          <>
            <DisclosureComponent.Button  as={Fragment}  >
              <div
                onClick={onLabelClick}

                className={cls("items-center group font-bold text-2xl leading-[normal] cursor-pointer select-none text-text-black  transition-colors",
                isContactPage ? "hover:text-tw-gray-300" : "hover:text-[#cb4405]"

                )}
              >
                { intl(panelLabel)}
              </div>
            </DisclosureComponent.Button>
            <Transition
              as={Fragment}
              show={show}
              enter="transition ease-out duration-150"
              enterFrom="transform opacity-0 translate-y-5"
              enterTo="transform opacity-100 translate-y-0"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 translate-y-0"
              leaveTo="transform opacity-0 translate-y-5"
            >
              <DisclosureComponent.Panel className="text-tw-gray-500">
                {RenderLinks()}
              </DisclosureComponent.Panel>
            </Transition>
          </>
        )
      }}
    </DisclosureComponent>
  )
})

Disclosure.displayName = "Disclosure"

export default memo(Disclosure)
