import React, { memo } from 'react'
import { Provider, AlertProviderProps as ProviderProps, transitions } from 'react-alert'
import { Children } from '~types'
import Alert from './Alert'

export interface AlertProviderProps extends Omit<ProviderProps, 'template' | 'context'> {
  children: Children
  template?: ProviderProps['template']
}

const AlertProvider: React.FC<AlertProviderProps> = props => {
  const { children, template = Alert, ...rest } = props

  const alertOptions = {
    position: 'top right' as const,
    timeout: 2000,
    offset: '25px 30px -10px',
    transition: transitions.SCALE,
    ...rest
  }

  return (
    <Provider template={template} {...alertOptions}>
      {children}
    </Provider>
  )
}

export default memo(AlertProvider)
