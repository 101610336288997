import { graphql, useStaticQuery } from "gatsby"
import { SiteMetadata } from "~types"

const useSiteMetadata = () => {
  return useStaticQuery<SiteMetadata>(
    graphql`
      query {
        site {
          siteMetadata {
            navigation {
              label
              path
              sections {
                label
                link
              }
            }
          }
        }
      }
    `
  ).site.siteMetadata
}

export default useSiteMetadata
