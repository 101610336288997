import React, { memo } from 'react'
import { Children } from '~types'

export interface AuthLayoutProps {
  children: Children
}

const AuthLayout: React.FC<AuthLayoutProps> = props => {
  const { children } = props

  return <>{children}</>
}

export default memo(AuthLayout)
