import { useState } from "react"
import { useReactiveVar } from "@apollo/client"
import { useIntl as useReactIntl } from "react-intl"
import { cacheLocale } from "~cache"

export type Locale = "fr" | "en"

const useIntl = (): [any, Locale, any] => {
  if (typeof window === "undefined") {
    return [() => "", "en", () => ""]
  }

  const apolloCacheLocale = useReactiveVar(cacheLocale) as Locale

  const [locale, setLocale] = useState<Locale>(apolloCacheLocale)

  const intl = useReactIntl().formatMessage

  const handleLocale = (locale: Locale) => {
    localStorage.setItem("locale", locale)
    setLocale(locale)
    cacheLocale(locale)
  }

  const handleIntl = (id: string, values?: any) => intl({ id }, { values })

  return [handleIntl, locale, handleLocale]
}

export default useIntl
