import React, { useState, memo } from "react"
import dayjs, { Dayjs } from "dayjs"
import { DateTime } from "~utils"
import DatePicker, { DatePickerProps } from "./DatePicker"

export interface DateRangePickerProps
  extends Omit<DatePickerProps, "dates" | "onDateChange"> {
  startDate: Date | null
  endDate: Date | null
  onDatesChange: (startDate: Date | null, endDate: Date | null) => void
}

export const rangedBetweenDays = (
  day: Dayjs,
  firstDate: Dayjs,
  secondDate: Dayjs
) => DateTime.isBetweenBy(day, firstDate, secondDate)

const DateRangePicker: React.FC<DateRangePickerProps> = props => {
  const {
    disabledDays,
    disabledMonths,
    onDatesChange,
    rangedDays = rangedBetweenDays,
  } = props

  const [startDate, setStartDate] = useState<Dayjs | null>(
    dayjs(props.startDate)
  )
  const [endDate, setEndDate] = useState<Dayjs | null>(
    DateTime.max(dayjs(props.endDate), startDate!.add(1, "month"))
  )

  const handleDatePickerDateChange = (date: Dayjs) => {
    if (!startDate) {
      if (endDate && DateTime.diff(date, endDate) > 0) {
        onDatesChange(endDate.toDate(), date.toDate())
        setStartDate(endDate)
        setEndDate(date)
        return
      }

      onDatesChange(date.toDate(), endDate && endDate.toDate())
      setStartDate(date)
      return
    }

    if (!endDate) {
      if (startDate && DateTime.diff(startDate, date) > 0) {
        onDatesChange(date.toDate(), startDate.toDate())
        setStartDate(date)
        setEndDate(startDate)
        return
      }

      onDatesChange(startDate.toDate(), date.toDate())
      setEndDate(date)
      return
    }

    if (startDate && endDate) {
      const isStartDate = DateTime.diff(date, endDate) > 0

      if (isStartDate) {
        onDatesChange(date.toDate(), null)
        setStartDate(date)
        setEndDate(null)
      } else {
        onDatesChange(null, date.toDate())
        setStartDate(null)
        setEndDate(date)
      }

      return
    }
  }

  const startDisabledMonths = (date: Dayjs, withYear?: boolean) => {
    const now = dayjs()

    if (disabledMonths) {
      return disabledMonths(date, withYear)
    } else {
      return now.month() < date.month() && now.year() === date.year()
    }
  }

  const endDisabledMonths = (date: Dayjs, withYear?: boolean) => {
    const now = dayjs()

    if (disabledMonths) {
      return disabledMonths(date, withYear)
    } else {
      return now.month() + 1 > date.month() && now.year() === date.year()
    }
  }

  const handleRangedDays = (day: Dayjs) =>
    startDate && endDate ? rangedDays(day, startDate, endDate) : true
  const handleDisabledDays = (day: Dayjs, date: Dayjs | null) => {
    if (disabledDays) return disabledDays(day)
    else return date ? day.month() === date.month() : false
  }

  return (
    <div className={"inline-flex"}>
      <DatePicker
        className={"no-border-r rounded-tr-0 rounded-br-0"}
        dates={[startDate, endDate]}
        onDateChange={(_, date) => handleDatePickerDateChange(dayjs(date))}
        disabledDays={(day: Dayjs) => handleDisabledDays(day, endDate)}
        disabledMonths={startDisabledMonths}
        rangedDays={handleRangedDays}
      />
      <DatePicker
        className={"no-border-l rounded-tl-0 rounded-bl-0"}
        dates={[startDate, endDate]}
        onDateChange={(_, date) => handleDatePickerDateChange(dayjs(date))}
        disabledDays={(day: Dayjs) => handleDisabledDays(day, startDate)}
        disabledMonths={endDisabledMonths}
        rangedDays={handleRangedDays}
      />
    </div>
  )
}

export default memo(DateRangePicker)
