import React, { memo } from "react"
import { ApolloProvider, AlertProvider, IntlProvider } from "~providers"
import { Children } from "~types"

export interface RootElementProps {
  element: Children
}

const RootElement: React.FC<RootElementProps> = props => {
  const { element: root } = props

  return (
    <ApolloProvider>
      <AlertProvider>
        <IntlProvider>{root}</IntlProvider>
      </AlertProvider>
    </ApolloProvider>
  )
}

export default memo(RootElement)
