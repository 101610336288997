import React, { memo, useState } from "react"
import { useLocation } from "react-use"
import { useWindowSize } from "~hooks"
import { NavigationMobile } from "~sections"
import { navigate } from "gatsby"
import cls from "classnames"

export interface HeaderProps {}

const Header: React.FC<HeaderProps> = props => {
  const {} = props

  const location = useLocation().pathname

  const isContactPage = location?.includes("contact")
  const isIndexLocation = location === "/"
  const isRenderSecondaryHeader = isIndexLocation || isContactPage

  const { toRender: isMobile } = useWindowSize(["mobile", "landscape"])

  return (
    <header className={cls("absolute top-0 w-full")}>
      {isMobile ? (
        <div>
          <NavigationMobile />
          <div
            onClick={() => navigate("/")}
            className={cls(
              !isContactPage && "bg-tw-white bg-opacity-80",
              "flex justify-center z-60 fixed w-[100%] h-[80px] left-0 items-center text-[16px] text-text-black "
            )}
          >
            MERCURIS AVOCATS
          </div>
        </div>
      ) : (
        <>
          {isRenderSecondaryHeader ? (
            <img
              onClick={() => navigate("/")}
              className={"px-[5rem] pt-[2rem] cursor-pointer"}
              src="/images/mercuris-logo.png"
              alt=""
            />

          ) : (
            <div
              onClick={() => navigate("/")}
              className={`select-none py-[2.5rem] cursor-pointer  pr-[5rem] 
                  fixed bg-tw-white bg-opacity-80  ml-[500px] 
              `}
            >
              <img
                onClick={() => navigate("/")}
                src="/images/mercuris-logo.png"
                alt=""
              />
            </div>
          )}
        </>
      )}
    </header>
  )
}

export default memo(Header)
