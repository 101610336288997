import React, { memo } from 'react'
import { ApolloProvider as Provider } from '@apollo/client'
import client from './apollo-client'
import { Children } from '~types'

export interface ApolloProviderProps {
  children: Children
}

const ApolloProvider: React.FC<ApolloProviderProps> = props => {
  const { children } = props

  return (
    <Provider client={client} {...props}>
      {children}
    </Provider>
  )
}

export default memo(ApolloProvider)
