import React, { memo, useRef } from "react"
import { Transition, TransitionClasses } from "@headlessui/react"
import {
  Popover as PopoverElement,
  PopoverPosition,
  PopoverProps as PopoverElementProps,
} from "react-tiny-popover"
import { Children } from "~types"
import { useClickAway } from "~hooks"
import cls from "classnames"

export interface PopoverProps
  extends Omit<
    PopoverElementProps,
    "isOpen" | "content" | "children" | "positions"
  > {
  show: boolean
  onHide: (value: boolean) => void
  children: Children
  panel: Children
  transition?: TransitionClasses
  position?: Exclude<PopoverPosition, "custom">
  className?: string
}

const Popover: React.FC<PopoverProps> = props => {
  const {
    show,
    onHide,
    children,
    panel,
    transition,
    className,
    position,
    containerClassName,
    ...rest
  } = props

  const popoverRef = useRef<HTMLDivElement>(null)
  useClickAway(popoverRef, () => onHide(false))

  return (
    <PopoverElement
      isOpen={show}
      positions={[position || "top"]}
      align={"center"}
      containerClassName={cls(containerClassName)}
      content={
        <Transition
          appear
          as={"div"}
          show={show}
          enter="transform transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-10"
          enterTo="opacity-100 translate-y-0"
          leave="transform transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-10"
          {...transition}
        >
          <div ref={popoverRef}>{panel}</div>
        </Transition>
      }
      {...rest}
    >
      <div className={className}>{children}</div>
    </PopoverElement>
  )
}

export default memo(Popover)
