import React, { memo, useState } from 'react'
import cls from 'classnames'
import { Children, ChildrenFunc, ClassName, HtmlDivProps } from '~types'

export interface PanelProps extends Omit<HtmlDivProps, 'className'> {
  className?: ClassName
  overlayClassName?: ClassName
  children?: Children | ChildrenFunc<[boolean, boolean]>
  group?: boolean
  focusable?: boolean
  tabIndex?: number
}

const Panel: React.FC<PanelProps> = props => {
  const { className, overlayClassName, children, group, focusable, tabIndex, ...rest } = props

  const [focus, setFocus] = useState(false)
  const [hover, setHover] = useState(false)

  const onFocus = (e: React.FocusEvent<HTMLDivElement>) => {
    setFocus(true)
    props.onFocus!(e)
  }

  const onBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    setFocus(false)
    props.onBlur!(e)
  }

  const onMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
    setHover(true)
    props.onMouseEnter!(e)
  }

  const onMouseLeave = (e: React.MouseEvent<HTMLDivElement>) => {
    setHover(false)
    props.onMouseLeave!(e)
  }

  const renderChildren = () => {
    if (typeof children === 'function') {
      return (children as ChildrenFunc<[boolean, boolean]>)(hover, focus)
    }

    return children
  }

  return (
    <div
      className={cls(
        'relative p-2',
        {
          group,
          'cursor-pointer': props.onClick
        },
        className
      )}
      tabIndex={focusable ? tabIndex : -1}
      onFocus={onFocus}
      onBlur={onBlur}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...rest}
    >
      <div className={cls('absolute inset-0', overlayClassName)} />
      {renderChildren()}
    </div>
  )
}

Panel.displayName = 'Ui/Panel'
Panel.defaultProps = {
  focusable: false,
  tabIndex: 0,
  onFocus: () => null,
  onBlur: () => null,
  onMouseEnter: () => null,
  onMouseLeave: () => null
}
Panel.storybook = {
  focusable: { name: '', description: '' },
  tabIndex: { name: '', description: '' },
  onFocus: { name: '', description: '' },
  onBlur: { name: '', description: '' },
  onMouseEnter: { name: '', description: '' },
  onMouseLeave: { name: '', description: '' },
}

export default memo(Panel)
