export * from "./html"
export * from "./lists"
export * from "./forms"
export * from "./apollo"


export type BreakpointName = 'mobile' | 'landscape' | 'tablet' | 'desktop'

export enum Breakpoint {
  mobile = 576,
  landscape = 768,
  tablet = 992,
  desktop = 1440
}

export type ShortBreakpointName = 'xs' | 'sm' | 'md' | 'lg'

export enum ShortBreakpoint {
  xs = Breakpoint.mobile,
  sm = Breakpoint.landscape,
  md = Breakpoint.tablet,
  lg = Breakpoint.desktop
}

/*export type BreakpointName =
  | "2xs"
  | "xs"
  | "sm"
  | "md"
  | "lg"
  | "xl"
  | "2xl"
  | "3xl"
export enum Breakpoint {
  "2xs" = 320,
  xs = 416,
  sm = 576,
  md = 768,
  lg = 992,
  xl = 1280,
  "2xl" = 1440,
  "3xl" = 1536,
}*/

export type SiteMetadataNavigationRoute = {
  path: string
  label: string
  icon?: string
  sections?: { link: string; label: string }
}

export type SiteMetadataNavigation = SiteMetadataNavigationRoute[]

export type SiteMetadata = {
  site: {
    siteMetadata: {
      title: string
      author: string
      description: string
      siteUrl: string
      navigation: SiteMetadataNavigation
    }
  }
}
