import React, { memo } from "react"
import cls from "classnames"
import dayjs, { Dayjs } from "dayjs"
import { ClassName } from "~types"
import { DatePickerVariantType } from "../DatePicker"
import { DateTime } from "~utils"

type DatePickerDayType = "today" | "selected" | "disabled" | "range" | "out"

const datePickerDayPrimaryTodayVariant = "bg-violet-3"
const datePickerDayPrimarySelectVariant = "bg-violet-2"
const datePickerDayPrimaryDisabledVariant = "bg-neutral-9 text-neutral-5"
const datePickerDayPrimaryRangeVariant = "bg-violet-1"
const datePickerDayPrimaryOutVariant = "text-neutral-5"

const datePickerDayBaseVariant =
  "flex items-center justify-center p-5 cursor-pointer select-none rounded-6 text-14 transition-colors"
const datePickerDayPrimaryVariant = "text-neutral-1 hover:bg-violet-1"

const getDatePickerDayVariantClass = (
  variant: DatePickerVariantType,
  type?: DatePickerDayType
) => {
  const variants = {
    primary: datePickerDayPrimaryVariant,
  }

  const typeVariants = {
    primary: {
      today: datePickerDayPrimaryTodayVariant,
      selected: datePickerDayPrimarySelectVariant,
      disabled: datePickerDayPrimaryDisabledVariant,
      range: datePickerDayPrimaryRangeVariant,
      out: datePickerDayPrimaryOutVariant,
    },
  }

  return cls(
    datePickerDayBaseVariant,
    variants[variant],
    type && typeVariants[variant][type]
  )
}

export interface DatePickerDayProps {
  variant?: DatePickerVariantType
  day: Dayjs
  date: Dayjs
  dates: (Date | null)[]
  today?: Date
  setDates: (date: Date) => void
  disabledDays?: (day: Dayjs) => boolean
  rangedDays?: (day: Dayjs) => boolean
}

const DatePickerDay: React.FC<DatePickerDayProps> = memo(props => {
  const {
    variant = "primary",
    day,
    date,
    dates,
    today,
    setDates,
    disabledDays,
    rangedDays,
  } = props

  const findCurrentDayInDates = () =>
    dates.find(date => date && DateTime.isSameDay(day, dayjs(date)))

  let type

  const isToday = today && DateTime.isSameDay(day, dayjs(date))

  const isCurrent = findCurrentDayInDates()

  const isInRange = rangedDays && rangedDays(day)

  const isInMonth = day.month() === date.month() && day.year() === date.year()

  const isDisabled = disabledDays && disabledDays(day)

  if (isToday) {
    type = "today"
  }

  if (isCurrent) {
    type = "selected"
  }

  if (isDisabled) {
    type = "disabled"
  }

  if (isInRange && !isDisabled && isInMonth) {
    type = "range"
  }

  if (!isInMonth && !isDisabled) {
    type = "out"
  }

  const style = {
    gridColumn: `(${day.date()} % 7) / span 1`,
  }

  return (
    <div
      className={getDatePickerDayVariantClass(
        variant,
        type as DatePickerDayType
      )}
      style={style}
      onClick={() => !isDisabled && setDates(day.toDate())}
    >
      {props.children}
    </div>
  )
})

DatePickerDay.displayName = "DatePickerDay"

const datePickerDaysBaseVariant = "grid grid-cols-7 gap-3 px-20"
const datePickerDaysPrimaryVariant = ""

const getDatePickerDaysVariantClass = (
  variant: DatePickerVariantType,
  className?: ClassName
) => {
  return cls(className, datePickerDaysBaseVariant, {
    [`${datePickerDaysPrimaryVariant}`]: !variant || variant === "primary",
  })
}

export interface DatePickerDaysProps {
  className?: ClassName
  variant?: DatePickerVariantType
  calendarDate: Dayjs
  currentDate: Dayjs
  dates: Date[]
  setDates: (date: Date) => void
  disabledDays?: (day: Dayjs) => boolean
  rangedDays?: (day: Dayjs) => boolean
}

const DatePickerDays: React.FC<DatePickerDaysProps> = props => {
  const {
    className,
    variant = "primary",
    calendarDate,
    currentDate,
    dates,
    setDates,
    disabledDays,
    rangedDays,
  } = props
  const days = DateTime.calendar(calendarDate)

  return (
    <div className={getDatePickerDaysVariantClass(variant, className)}>
      {days.map(day => {
        return (
          <DatePickerDay
            key={day.toISOString()}
            date={currentDate}
            day={day}
            dates={dates}
            variant={variant}
            setDates={setDates}
            disabledDays={disabledDays}
            rangedDays={rangedDays}
          >
            {day.date()}
          </DatePickerDay>
        )
      })}
    </div>
  )
}

export default memo(DatePickerDays)
