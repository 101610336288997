import React, { memo, useState } from "react"
import cls from "classnames"
import dayjs, { Dayjs } from "dayjs"
import { ClassName } from "~types"
import { DateTime } from "~utils"
import {
  DatePickerHeader,
  DatePickerDaysLabels,
  DatePickerDays,
} from "./components"

export type DatePickerVariantType = "primary"

const datePickerBaseVariant = "py-20 border rounded-8 shadow-drop active-ring"
const datePickerPrimaryVariant = "border-neutral-9 bg-neutral-13"

export const getDatePickerVariantClass = (
  variant: DatePickerVariantType,
  className?: ClassName
) => {
  return cls(className, datePickerBaseVariant, {
    [`${datePickerPrimaryVariant}`]: !variant || variant === "primary",
  })
}

export interface DatePickerProps {
  className?: ClassName
  variant?: DatePickerVariantType
  dates: (Date | Dayjs | null)[]
  noToday?: boolean
  onDateChange: (dates: Date[], date: Date) => void
  disabledMonths?: (date: Dayjs, withYear?: boolean) => boolean
  disabledDays?: (day: Dayjs) => boolean
  rangedDays?: (day: Dayjs) => boolean
}

export const disabledPastDays = (day: Dayjs) =>
  DateTime.diff(dayjs(), day, "days") > 0
export const disabledPastMonths = (date: Dayjs, withYear?: boolean) => {
  const today = dayjs()
  const diff = dayjs.duration(today.diff(date))

  const isAfterMonths = diff.months() > 0
  const isYearMonths = withYear ? diff.years() > 0 : true

  return isAfterMonths && isYearMonths
}

const DatePicker: React.FC<DatePickerProps> = props => {
  const {
    dates,
    className,
    variant = "primary",
    onDateChange,
    disabledMonths = disabledPastMonths,
    disabledDays = disabledPastDays,
    rangedDays = () => false,
  } = props

  const [dateId, handleDateId] = useState<number>(0)

  const nextDateId = () => {
    const nextId = dateId + 1
    const datesLength = dates.length - 1

    if (nextId > datesLength) handleDateId(0)
    else handleDateId(nextId)
  }

  const currentDate = dayjs(dates[dateId])
  const [calendarDate, setCalendarDate] = useState(currentDate)

  const getNextDate = (date: Dayjs | null) => (date && date.toDate()) as Date

  const getNextDates = (date: Date | null): Date[] => {
    const nextDates = [...dates]
    nextDates[dateId] = date

    return nextDates as Date[]
  }

  const setDates = (date: Dayjs | null) => {
    const nextDate = getNextDate(date)
    const nextDates = getNextDates(nextDate)
    onDateChange(nextDates, nextDate)
    nextDateId()
  }

  const setPreviousMonth = () =>
    setCalendarDate(calendarDate.subtract(1, "month"))
  const setNextMonth = () => setCalendarDate(calendarDate.add(1, "month"))
  const setPreviousYear = () =>
    setCalendarDate(calendarDate.subtract(1, "year"))
  const setNextYear = () => setCalendarDate(calendarDate.add(1, "year"))
  const previousMonth = (e: React.MouseEvent<HTMLOrSVGElement>) =>
    e.shiftKey ? setPreviousYear() : setPreviousMonth()
  const nextMonth = (e: React.MouseEvent<HTMLOrSVGElement>) =>
    e.shiftKey ? setNextYear() : setNextMonth()

  return (
    <div className={getDatePickerVariantClass(variant, className)}>
      <DatePickerHeader
        date={calendarDate}
        variant={variant}
        nextMonth={nextMonth}
        previousMonth={previousMonth}
        disabledMonths={disabledMonths}
      />
      <div className={cls("flex")}>
        <div className={cls("w-full")}>
          <DatePickerDaysLabels variant={variant} />
          <DatePickerDays
            calendarDate={calendarDate}
            currentDate={currentDate}
            variant={variant}
            dates={dates as Date[]}
            setDates={date => setDates(dayjs(date))}
            disabledDays={disabledDays}
            rangedDays={rangedDays}
          />
        </div>
      </div>
    </div>
  )
}

export default memo(DatePicker)
