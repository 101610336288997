import React, { memo } from 'react'
import cls from 'classnames'
import Icon from '~svg'
import { ClassName } from '~types'
import { DatePickerVariantType } from '../DatePicker'
import { Dayjs } from 'dayjs'

const datePickerHeaderBaseVariant = 'relative flex items-center justify-between pb-20 px-20 border-b'
const datePickerHeaderPrimaryVariant = 'border-neutral-9'

const getDatePickerHeaderVariantClass = (variant: DatePickerVariantType, className?: ClassName) => {
  return cls(className, datePickerHeaderBaseVariant, {
    [`${datePickerHeaderPrimaryVariant}`]: !variant || variant === 'primary'
  })
}

export interface DatePickerHeaderProps {
  date: Dayjs
  className?: ClassName
  variant?: DatePickerVariantType
  nextMonth: (e: React.MouseEvent<HTMLDivElement>) => void
  previousMonth: (e: React.MouseEvent<HTMLDivElement>) => void
  disabledMonths: (date: Dayjs, withYear?: boolean) => boolean
}

const DatePickerHeader = (props: DatePickerHeaderProps) => {
  const { className, variant = 'primary', date, nextMonth, previousMonth, disabledMonths } = props

  const title = date.format('MMMM, YYYY')
  const prevMonthDate = date.subtract(1, 'month')
  const nextMonthDate = date.add(1, 'month')

  const isPrevMonthDisabled = (withYear?: boolean) => disabledMonths(prevMonthDate, withYear)
  const isNextMonthDisabled = (withYear?: boolean) => disabledMonths(nextMonthDate, withYear)

  const handlePrevMonthChange = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.shiftKey) {
      if (!isPrevMonthDisabled(true)) previousMonth(e)
    } else {
      if (!isPrevMonthDisabled()) previousMonth(e)
    }
  }

  const handleNextMonthChange = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.shiftKey) {
      if (isNextMonthDisabled(true)) nextMonth(e)
    } else {
      if (!isNextMonthDisabled()) nextMonth(e)
    }
  }

  return (
    <div className={getDatePickerHeaderVariantClass(variant, className)}>
      <h4 className={cls({ 'text-neutral-1': !variant || variant === 'primary' })}>{title}</h4>
      <nav className={'flex items-center'}>
        <Icon
          icon={'ArrowChevronLeft'}
          className={cls('w-16 h-12 mr-16 cursor-pointer', {
            'neutral-icon': !variant || variant === 'primary',
            hidden: isPrevMonthDisabled()
          })}
          onClick={handlePrevMonthChange}
        />
        <Icon
          icon={'ArrowChevronRight'}
          className={cls('w-16 h-12 cursor-pointer', {
            'neutral-icon': !variant || variant === 'primary',
            hidden: isNextMonthDisabled()
          })}
          onClick={handleNextMonthChange}
        />
      </nav>
    </div>
  )
}

export default memo(DatePickerHeader)
