import React, { forwardRef, memo } from "react"
import { action } from "@storybook/addon-actions"
import { Children, ClassName, HtmlButtonProps } from "~types"
import { Cls, Sb, enumValues } from "~utils"
import "./Button.css"

export enum ButtonSize {
  lg = "lg",
  md = "md",
  sm = "sm",
}

export enum ButtonVariant {
  indigo = "indigo",
  blue = "blue",
  yellow = "yellow",
  red = "red",
}

export interface ButtonProps extends Omit<HtmlButtonProps, "className"> {
  className?: ClassName
  size?: keyof typeof ButtonSize
  variant?: keyof typeof ButtonVariant
  children: Children
  loading?: boolean
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    className,
    size,
    variant,
    children,
    loading,
    disabled,
    tabIndex,
    ...rest
  } = props
  const isDisabled = disabled || loading

  const classes = {
    [`btn-${size}`]: Boolean(size),
    [`btn-${variant}`]: Boolean(variant),
    [`btn-disabled`]: isDisabled,
  }

  return (
    <button
      ref={ref}
      className={Cls("btn", classes, "justify-center", className)}
      disabled={isDisabled}
      tabIndex={tabIndex || 1}
      {...rest}
    >
      {children}
    </button>
  )
})

const MemoButton = memo(Button)
MemoButton.displayName = "Ui/Button"
MemoButton.defaultProps = {
  className: "",
  children: "Button",
  loading: false,
  disabled: false,
  onClick: action("onClick"),
}

MemoButton.storybook = {
  className: Sb({
    name: "Class",
    description: "Set button class",
  }),
  size: Sb({
    name: "Size",
    description: "Set button size",
    type: "enum",
    options: enumValues(ButtonSize) as string[],
    defaultValue: "lg",
  }),
  variant: Sb({
    name: "Variant",
    description: "Set button variant",
    type: "enum",
    options: enumValues(ButtonVariant) as string[],
  }),
  children: Sb({
    name: "Children",
    description: "Set button children",
  }),
  loading: Sb({
    name: "Loading",
    description: "Toggle button loading state",
    type: "boolean",
  }),
  disabled: Sb({
    name: "Disabled",
    description: "Toggle button disabled state",
    type: "boolean",
  }),
  onClick: Sb({
    name: "onClick",
    description: "onClick action",
    control: null,
  }),
}

export default MemoButton
