/* TODO tests and description */
// https://storybook.js.org/docs/react/essentials/controls
import { ArgType } from '@storybook/react'

type SbArgTypesType = 'boolean' | 'number' | 'object' | 'array' | 'enum' | 'string'
type SbArgTypesControl =
  | null
  | 'boolean'
  | 'number'
  | 'range'
  | 'object'
  | 'file'
  | 'radio'
  | 'inline-radio'
  | 'check'
  | 'inline-check'
  | 'select'
  | 'multi-select'
  | 'text'
  | 'color'
  | 'date'

interface SbArgTypes {
  name: string
  description: string
  type?: SbArgTypesType
  options?: string[]
  control?: SbArgTypesControl
  defaultValue?: any
  required?: boolean
  summary?: string
  detail?: string
}

export default (argTypes: SbArgTypes): ArgType => {
  const { name, description, type, options, summary, detail, required, defaultValue } = argTypes

  let control = { type: argTypes.control }

  if (!argTypes.control && type) {
    switch (type) {
      case 'boolean': {
        control = { type: 'boolean' }
        break
      }
      case 'number': {
        control = { type: 'number' }
        break
      }
      case 'object': {
        control = { type: 'object' }
        break
      }
      case 'array': {
        control = { type: 'select' }
        break
      }
      case 'enum': {
        control = { type: 'inline-radio' }
        break
      }
      default: {
        control = { type: 'text' }
        break
      }
    }
  }

  return {
    name,
    description,
    type: { name: type || 'string', required },
    options,
    control,
    defaultValue,
    table: {
      type: {
        summary,
        detail
      }
    }
  }
}
