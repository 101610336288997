import { Link, useStaticQuery, graphql } from "gatsby"
import React, { memo, useEffect, useState } from "react"
import { useLocation } from "@reach/router"
import cls from "classnames"
import { NavigationItemType } from "~types"
import { useIntl, useSiteMetadata, useWindowSize } from "~hooks"
import { Disclosure } from "~ux"
import { LanguageSwitcher } from "~ui"
import * as Icon from "~svg"

export interface NavigationMobileProps {}

const NavigationMobile: React.FC<NavigationMobileProps> = props => {
  const {} = props
  const [intl] = useIntl()


  const { pathname } = useLocation()

  const { navigation } = useSiteMetadata()

  const pathArray = pathname.split("/")
  const currentRoute = pathArray[pathArray.length - 1]
  const isContactPage = pathname.includes("contact")

  const [isAvocats, setIsAvocats] = useState<boolean>(false)
  const [isCompetences, setCompetences] = useState<boolean>(false)

  //const { toRender: isMobile } = useWindowSize(["landscape"])
  const [showBurger, setIsShowBurger] = useState<boolean>(false)

  useEffect(() => {
    setIsAvocats(pathname.split("/").includes("equipe"))
    setCompetences(pathname.split("/").includes("competences"))
  }, [pathname])

  const expretises = useStaticQuery(
    graphql`
      query {
        expretises: allMarkdownRemark {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                label
                priority
                advocates {
                  position
                }
              }
            }
          }
        }
      }
    `
  ).expretises.edges

  const expretisesLinks = expretises
    .map(edge => {
      const {
        node: {
          fields: { slug },
          frontmatter: { label, icon, priority },
        },
      } = edge

      return {
        path: slug,
        label,
        icon,
        priority,
      }
    })
    .filter(item => item.path.includes("competences"))
    .sort((a, b) => {
      return a.priority - b.priority
    })

  const advocatesLinks = expretises
    .map(edge => {
      const {
        node: {
          fields: { slug },
          frontmatter: { label, icon, advocates, priority },
        },
      } = edge

      return {
        path: slug,
        position: advocates && advocates[0].position,
        label,
        icon,
        priority,
      }
    })
    .filter(item => !item.path.includes("competences"))

  const firstAdvocateLink = advocatesLinks[1].path

  const renderCompetences = (item: NavigationItemType) => {
    return (
      <div key={item.path}>
        <Disclosure
          onLabelClick={() => {
            setIsAvocats(false)
            setCompetences(!isCompetences)
          }}
          onBurgerClose={() => setIsShowBurger(false)}
          panelLabel={item.label}
          panelPath={item.path}
          links={expretisesLinks}
          show={isCompetences}
          currentRoute={currentRoute}
          name="competences"
        />
      </div>
    )
  }

  const renderAdvocates = (item: NavigationItemType) => {
    return (
      <div key={item.path}>
        <Disclosure
          onLabelClick={() => {
            setCompetences(false)
            setIsAvocats(!isAvocats)
          }}
          onBurgerClose={() => {
            setIsShowBurger(false)
          }}
          panelLabel={item.label}
          panelPath={firstAdvocateLink}
          links={advocatesLinks}
          show={isAvocats}
          currentRoute={currentRoute}
          name="advocates"
        />
      </div>
    )
  }

  return (
    <div className={`fixed left-0 z-30 overflow-y-scroll `}>
      <div className={cls("w-20 h-20 flex items-center justify-center")}>
        <Icon.BurgerMenu
          showBurger={showBurger}
          onClick={() => setIsShowBurger(prevState => !prevState)}
          className={"text-text-black w-[16px] h-[16px]"}
        />
      </div>

      {showBurger && (
        <ul
          className={cls("w-screen p-[25px]", !isContactPage && "bg-[white]")}
        >
          {navigation.map((item, index) => {
            const isAccueilLink = item.path === "/"

            const active = isAccueilLink
              ? pathname === "/"
              : pathname.includes(item.path)

            const isCompetences = item.path === "/competences"
            const isAvocate = item.path === "/equipe"

            if (isCompetences) {
              return renderCompetences(item)
            }

            if (isAvocate) {
              return renderAdvocates(item)
            }

            return (
              <li
                key={item.label}
                onClick={() => {
                  setIsShowBurger(false)
                }}
              >
                <Link
                  key={item.label}
                  to={item.path}
                  className={cls(
                    "group font-bold text-2xl  text-text-black hover:text-tw-gray-300 transition-colors"
                  )}
                >
                  <span
                    className={"leading-[none]"}
                    onClick={() => {
                      setIsShowBurger(false)
                    }}
                  >
                    {intl(item.label)}
                  </span>
                </Link>
              </li>
            )
          })}
          <LanguageSwitcher />
        </ul>
      )}
    </div>
  )
}

export default memo(NavigationMobile)
