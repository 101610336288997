import React, { memo, forwardRef } from "react"
import { CheckIcon } from "@heroicons/react/solid"
import { action } from "@storybook/addon-actions"
import { ClassName, HtmlInputProps } from "~types"
import { Cls, Sb, enumValues } from "~utils"
import "./Checkbox.css"

export enum CheckboxVariant {
  indigo = "indigo",
  blue = "blue",
  yellow = "yellow",
  red = "red",
}

export interface CheckboxProps
  extends Omit<
    HtmlInputProps,
    "type" | "className" | "ref" | "value" | "onChange"
  > {
  name: string
  value: boolean
  className?: ClassName
  variant?: keyof typeof CheckboxVariant
  loading?: boolean
  disabled?: boolean
  onChange: (value: boolean) => void
}

const Checkbox: React.FC<CheckboxProps> = forwardRef<
  HTMLInputElement,
  CheckboxProps
>((props, ref) => {
  const {
    name,
    className,
    variant,
    value,
    loading,
    disabled,
    onChange,
    tabIndex,
    ...rest
  } = props
  const isVariant = Boolean(variant)
  const isDisabled = disabled || loading

  const classes = {
    [`checkbox-${variant}`]: isVariant,
    ["checkbox-disabled"]: isDisabled,
  }

  const defaultVariant =
    !isVariant &&
    "bg-tw-gray-400 hover:bg-tw-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-1 ring-offset-tw-gray-200 focus:ring-tw-gray-300 shadow-md"

  return (
    <>
      <input
        className={"hidden"}
        id={name}
        name={name}
        ref={ref}
        type="checkbox"
        checked={value}
        onChange={({ currentTarget: { checked } }) => onChange(checked)}
        disabled={isDisabled}
        {...rest}
      />
      <label
        htmlFor={name}
        className={Cls("checkbox", defaultVariant, classes, className)}
        tabIndex={tabIndex || 1}
        data-testid={"checkbox"}
      >
        {value && <CheckIcon />}
      </label>
    </>
  )
})

const MemoCheckbox = memo(Checkbox)
MemoCheckbox.displayName = "Ui/Checkbox"
MemoCheckbox.defaultProps = {
  name: "checkbox",
  value: false,
  className: "",
  loading: false,
  disabled: false,
  onChange: action("onChange"),
}
MemoCheckbox.storybook = {
  name: Sb({
    name: "Name",
    description: "Set checkbox name",
  }),
  className: Sb({
    name: "Class",
    description: "Set checkbox class",
  }),
  variant: Sb({
    name: "Variant",
    description: "Set checkbox variant",
    type: "enum",
    options: enumValues(CheckboxVariant) as string[],
  }),
  value: Sb({
    name: "Value",
    description: "Set checkbox value",
    control: "boolean",
  }),
  loading: Sb({
    name: "Loading",
    description: "Toggle checkbox loading state",
    type: "boolean",
  }),
  disabled: Sb({
    name: "Disabled",
    description: "Toggle checkbox disabled state",
    type: "boolean",
  }),
  onChange: Sb({
    name: "onChange",
    description: "onChange action",
    control: null,
  }),
}

export default MemoCheckbox
