import React, { memo } from 'react'
import cls from 'classnames'
import { ClassName } from '~types'
import { DatePickerVariantType } from '../DatePicker'
import { DateTime } from '~utils'

const datePickerDaysLabelsBaseVariant = 'text-center text-12'
const datePickerDaysLabelsPrimaryVariant = 'text-neutral-3'

const getDatePickerDaysLabelsVariantClass = (variant: DatePickerVariantType, className?: ClassName) => {
  return cls(className, datePickerDaysLabelsBaseVariant, {
    [`${datePickerDaysLabelsPrimaryVariant}`]: !variant || variant === 'primary'
  })
}

export interface DatePickerDaysLabelsProps {
  className?: ClassName
  variant?: DatePickerVariantType
}

// Render the label for day of the week
// This method is used as a map callback as seen here
const DatePickerDaysLabels: React.FC<DatePickerDaysLabelsProps> = props => {
  const { className, variant = 'primary' } = props

  const week = DateTime.week()

  return (
    <div className={'grid grid-cols-7 gap-3 px-20 py-18'}>
      {week.map(day => {
        return (
          <div className={getDatePickerDaysLabelsVariantClass(variant, className)} key={day.toISOString()}>
            {day.format('dd')}
          </div>
        )
      })}
    </div>
  )
}

export default memo(DatePickerDaysLabels)
