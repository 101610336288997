const cells = new Array(14).fill(0).map((_, index) => index + 1)

module.exports = function ({ addComponents }) {
  const gridComponents = {}

  cells.forEach(qn => {
    gridComponents[`.grid-cols-${qn}-min`] = {
      'grid-template-columns': `repeat(${qn}, min-content)`
    }
  })

  addComponents(gridComponents)
}
