import React, { memo } from "react"
import { useWindowSize } from "~hooks"
import { useLocation } from "react-use"
import cls from "classnames"
import * as Icon from "~svg"

export interface FooterProps {}

const FooterProps: React.FC<FooterProps> = () => {
  const location = useLocation().pathname
  const isContactPage = location?.includes("contact")
  const isMainPage = location === "/"
  const isAbsoluteFooter = isContactPage || isMainPage

  const { toRender: isMobile } = useWindowSize(["mobile", "landscape"])

  const renderMobileFooter = () => {
    return (
      <div
        className={cls(
          "text-text-black block container px-[0px] py-[45px]  justify-center items-center text-center min-w-[100%]",
          location == "/contact" ? "bg-[#cb4405] absolute bottom-0" : ""
        )}
      >
        {location == "/" && (
          <div className="font-bold hover:text-tw-gray-300">
            <a href="mailto:info@mercuris.legal">info@mercuris.legal</a>
          </div>
        )}
        {isContactPage && (
          <div>
            <div className="flex flex-col font-bold">
              <a
                className="mb-[15px] hover:text-tw-gray-300 "
                target={"_blank"}
                href="https://www.google.com/maps/place/Rue+du+Grand-Ch%C3%AAne+2,+1003+Lausanne,+Switzerland/@46.5198336,6.6312091,19z/data=!3m1!4b1!4m5!3m4!1s0x478c2e32597aad9b:0x9893bfe7218cf688!8m2!3d46.5198336!4d6.6317563"
              >
                RUE DU GRAND-CHÊNE 2, 1002 LAUSANNE
              </a>

              <a
                className="mb-[15px] hover:text-tw-gray-300"
                target={"_blank"}
                href="https://www.google.com/maps/place/Pl.+des+Eaux-Vives+3,+1207+Gen%C3%A8ve,+Switzerland/@46.2019477,6.1556214,18.13z/data=!4m5!3m4!1s0x478c6531500cf06b:0x344ff7725fee2995!8m2!3d46.2022105!4d6.1567061"
              >
                PLACE DES EAUX-VIVES 3, 1207 GENÈVE
              </a>
            </div>
            <div className="flex flex-col font-bold">
              <a
                href="tel:+41213209090"
                className="hover:text-tw-gray-300 mb-[15px]"
              >
                T+41(21)320 90 90
              </a>
              <a
                href="tel:+41223421111"
                className="hover:text-tw-gray-300 mb-[15px]"
              >
                T+41(22)342 11 11
              </a>
            </div>
          </div>
        )}
        <div className="flex justify-center items-center text-center gap-x-[10px]">
          <a
            href="https://www.linkedin.com/company/27250358/"
            target={"_blank"}
          >
            <Icon.Linkedin className="w-[25px] h-[25px] text-text-black hover:text-tw-gray-300" />
          </a>

          <a href="mailto:info@mercuris.legal" target={"_blank"}>
            <Icon.Message className="w-[35px] h-[25px] text-text-black hover:text-tw-gray-300" />
          </a>
        </div>
        <div className="text-[0.875rem] font-bold mt-2">
          © 2021 MERCURIS AVOCATS –
        </div>

        <div className="text-[0.875rem] font-bold">MEMBRES OAV, ODAGE, FSA</div>
      </div>
    )
  }

  return (
    <footer
      className={cls(
        "relative z-50 w-full",
        window.innerHeight < 750 && !isMobile && "hidden"
      )}
    >
      {isMobile ? (
        renderMobileFooter()
      ) : (
        <div
          className={cls(
            isAbsoluteFooter && "absolute bottom-0",
            "container text-text-black flex justify-between min-w-[100%] items-center  mb-[35px]"
          )}
        >
          <div className="">
            <div className="flex">
              <div>
                <a
                  href="https://www.linkedin.com/company/27250358/"
                  target={"_blank"}
                >
                  <Icon.Linkedin className="w-[25px] h-[25px] text-text-black hover:text-tw-gray-300" />
                </a>
              </div>
              <div className="ml-2">
                <a href="mailto:info@mercuris.legal" target={"_blank"}>
                  <Icon.Message className="w-[35px] h-[25px] text-text-black hover:text-tw-gray-300" />
                </a>
              </div>
            </div>
            <div className="text-[0.875rem] font-bold mt-2">
              © 2021 MERCURIS AVOCATS –
            </div>
            <div className="text-[0.875rem] font-bold">
              MEMBRES OAV, ODAGE, FSA
            </div>
          </div>
          {isContactPage && (
            <div className="text-2xl font-bold">
              <div>
                <a
                  className="hover:text-tw-gray-300"
                  target={"_blank"}
                  href="https://www.google.com/maps/place/Rue+du+Grand-Ch%C3%AAne+2,+1003+Lausanne,+Switzerland/@46.5198336,6.6312091,19z/data=!3m1!4b1!4m5!3m4!1s0x478c2e32597aad9b:0x9893bfe7218cf688!8m2!3d46.5198336!4d6.6317563"
                >
                  RUE DU GRAND-CHÊNE 2, 1002 LAUSANNE{" "}
                </a>
              </div>
              <div>
                <a
                  className="hover:text-tw-gray-300"
                  target={"_blank"}
                  href="https://www.google.com/maps/place/Pl.+des+Eaux-Vives+3,+1207+Gen%C3%A8ve,+Switzerland/@46.2019477,6.1556214,18.13z/data=!4m5!3m4!1s0x478c6531500cf06b:0x344ff7725fee2995!8m2!3d46.2022105!4d6.1567061"
                >
                  PLACE DES EAUX-VIVES 3, 1207 GENÈVE
                </a>
              </div>
            </div>
          )}
          {isContactPage ? (
            <div className="text-2xl font-bold">
              <div>
                <a href="tel:+41213209090" className="hover:text-tw-gray-300">
                  T+41(21)320 90 90
                </a>
              </div>
              <div>
                <a href="tel:+41223421111" className="hover:text-tw-gray-300">
                  T+41(22)342 11 11
                </a>
              </div>
            </div>
          ) : (
            <div>
              {isMainPage && (
                <div className="text-2xl font-bold hover:text-tw-gray-300">
                  <a href="mailto:info@mercuris.legal">info@mercuris.legal</a>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </footer>
  )
}

export default memo(FooterProps)
