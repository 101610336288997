import React, { memo, forwardRef } from 'react'
import { ClassName, HtmlInputProps } from '~types'
import { action } from '@storybook/addon-actions'
import { CheckIcon } from '@heroicons/react/solid'
import { Cls, Sb, enumValues } from '~utils'
import './Radio.css'

export enum RadioVariant {
  indigo = 'indigo',
  blue = 'blue',
  yellow = 'yellow',
  red = 'red'
}

export interface RadioProps extends Omit<HtmlInputProps, 'type' | 'className' | 'ref' | 'value' | 'onChange'> {
  name: string
  value: boolean
  className?: ClassName
  variant?: keyof typeof RadioVariant
  loading?: boolean
  disabled?: boolean
  onChange: (value: boolean) => void
}

const Radio: React.FC<RadioProps> = forwardRef<HTMLInputElement, RadioProps>((props, ref) => {
  const { name, className, variant, value, loading, disabled, onChange, tabIndex, ...rest } = props
  const isVariant = Boolean(variant)
  const isDisabled = disabled || loading

  const classes = {
    [`radio-${variant}`]: isVariant,
    ['radio-disabled']: isDisabled
  }
  const defaultVariant =
    !isVariant &&
    'bg-tw-gray-400 hover:bg-tw-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-1 ring-offset-tw-gray-200 focus:ring-tw-gray-300 shadow-md'

  return (
    <>
      <input
        className={'hidden'}
        id={name}
        name={name}
        ref={ref}
        type="radio"
        checked={value}
        onChange={({ currentTarget: { checked } }) => onChange(checked)}
        disabled={isDisabled}
        {...rest}
      />
      <label
        htmlFor={name}
        className={Cls('radio', defaultVariant, classes, className)}
        tabIndex={tabIndex || 1}
        data-testid={'radio'}
      >
        {value && <CheckIcon />}
      </label>
    </>
  )
})

const MemoRadio = memo(Radio)

MemoRadio.displayName = 'Ui/Radio'
MemoRadio.defaultProps = {
  name: 'radio',
  value: false,
  className: '',
  loading: false,
  disabled: false,
  onChange: action('onChange')
}
MemoRadio.storybook = {
  name: Sb({
    name: 'Name',
    description: 'Set radio name'
  }),
  className: Sb({
    name: 'Class',
    description: 'Set radio class'
  }),
  variant: Sb({
    name: 'Variant',
    description: 'Set radio variant',
    type: 'enum',
    options: enumValues(RadioVariant) as string[]
  }),
  value: Sb({
    name: 'Value',
    description: 'Set radio value',
    control: 'boolean'
  }),
  loading: Sb({
    name: 'Loading',
    description: 'Toggle radio loading state',
    type: 'boolean'
  }),
  disabled: Sb({
    name: 'Disabled',
    description: 'Toggle radio disabled state',
    type: 'boolean'
  }),
  onChange: Sb({
    name: 'onChange',
    description: 'onChange action',
    control: null
  })
}

export default MemoRadio
