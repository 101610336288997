import React, { memo } from 'react'
import loadable from '@loadable/component'
import { ClassName } from '~types'

export type IconProps = {
  className?: ClassName
  onClick?(e: React.MouseEvent<HTMLOrSVGElement>): void
}

export type IconType =
  |'Search'
  | 'Times'
  | 'Check'
  | 'ArrowChevronLeft'
  | 'ArrowChevronRight'
  | 'User'
  | 'Linkedin'
  | 'Message'
  | 'BurgerMenu'

import Linkedin from './icons/Linkedin'
import Message from './icons/Message'
import Check from './icons/Check'
import BurgerMenu from './icons/BurgerMenu'



export interface DynamicProps extends IconProps {
  icon: IconType
}

const Icon = loadable((props: DynamicProps) => import(`./icons/${props.icon}`), {
  cacheKey: props => props.icon
})


export {
  Linkedin,
  Message,
  Check,
  BurgerMenu,
}


export default memo(Icon)
