import { get } from 'lodash'
import { OptionType } from '~types'

/*
  Converts array of objects to options

  Example:
  arrayToOptions<ArrayItemType>([{ id: 1, title: 'Test' }], 'id', 'title')

  Result: [{ value: 1, label: 'Test' }]
*/
/* TODO tests */
export const arrayToOptions = <T, V = string>(
  arr: T[],
  valueKey: keyof T | ((item: T) => V),
  labelKey: keyof T | ((item: T) => string)
): OptionType<V>[] => {
  return arr.map(item => {
    return {
      value: typeof valueKey === 'function' ? valueKey(item) : get(item, valueKey),
      label: typeof labelKey === 'function' ? labelKey(item) : get(item, labelKey)
    } as OptionType<V>
  })
}

/*
  Converts enum to options

  Example:
  enum Enum {
    1 = 'test1',
    2 = 'test2,
    3 = 'test3,
  }

  enumToOptions(Enum)

  Result: [{ value: 'test1', label: 1 }, { value: 'test2', label: 2 }, { value: 'test3', label: 3 }]
*/
/* TODO tests */
export const enumToOptions = <T>(enumType: T): OptionType[] => {
  const keys = Object.keys(enumType)
  const values: string[] = Object.values(enumType)

  const options = []

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    const value = values[i]
    const isNumericKey = parseFloat(key)

    if (isNumericKey) continue

    options.push({
      value,
      label: key
    })
  }

  return options
}

/*
  Gets enum keys

  Example:
  enum Enum {
    1 = 'test1',
    2 = 'test2,
    3 = 'test3,
  }

  enumKeys(Enum)

  Result: [1, 2, 3]
*/
/* TODO tests */
export const enumKeys = <T>(enumType: T): string[] => enumToOptions(enumType).map(({ label }) => label)

/*
  Gets enum values

  Example:
  enum Enum {
    1 = 'test1',
    2 = 'test2,
    3 = 'test3,
  }

  enumKeys(Enum)

  Result: ['test1', 'test2', 'test3']
*/
/* TODO tests */
export const enumValues = <T>(enumType: T): (string | number)[] => enumToOptions(enumType).map(({ value }) => value)

/* TODO tests and description */
export const fileSize = (bytes: number) => {
  let fsize
  const fsizekb = bytes / 1024
  const fsizemb = fsizekb / 1024
  const fsizegb = fsizemb / 1024

  if (fsizekb <= 1024) {
    fsize = fsizekb.toFixed(1) + 'kb'
  } else if (fsizekb >= 1024 && fsizemb <= 1024) {
    fsize = fsizemb.toFixed(1) + 'Mb'
  } else if (fsizemb >= 1024 && fsizegb <= 1024) {
    fsize = fsizegb.toFixed(1) + 'Gb'
  }

  return fsize
}

/* TODO tests and description */
export const removeFieldRecursively = <T>(obj: T, field: string) => {
  for (const prop in obj) {
    if (prop === field) delete obj[prop]
    else if (typeof obj[prop] === 'object') removeFieldRecursively(obj[prop], field)
  }

  return obj
}

type FormDataPrimitive = string | Blob | number | boolean

interface FormDataNest {
  [x: string]: FormVal
}

type FormVal = FormDataNest | FormDataPrimitive

/* TODO tests and description */
export const buildFormData = (formData: FormData, data: FormVal, parentKey?: string) => {
  if (Array.isArray(data)) {
    data.forEach(el => {
      buildFormData(formData, el, parentKey)
    })
  } else if (typeof data === 'object' && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      buildFormData(formData, (data as any)[key], parentKey ? `${parentKey}.${key}` : key)
    })
  } else {
    if (!data) return

    const value = typeof data === 'boolean' || typeof data === 'number' ? data.toString() : data
    formData.append(parentKey as string, value)
  }
}

/* TODO tests and description */
export const getFormData = (data: Record<string, FormDataNest>) => {
  const formData = new FormData()

  buildFormData(formData, data)

  return formData
}
