const { keys } = require('lodash')

const sides = {
  right: 'r',
  left: 'l',
  top: 't',
  bottom: 'b',
  x: 'x',
  y: 'y',
  all: ''
}

const noRules = {
  padding: 'p',
  margin: 'm',
  border: 'border-'
}

module.exports = function ({ addUtilities }) {
  const sidesKeys = keys(sides)
  const noRulesKeys = keys(noRules)

  const noClassesUtilities = {}

  noRulesKeys.forEach(rule => {
    const ruleClass = noRules[rule]

    sidesKeys.forEach(side => {
      const sideClass = sides[side]
      const noClass = `.no-${ruleClass + sideClass}`
      const noClassValue = `0 !important`

      if (side === 'all') {
        if (ruleClass.endsWith('-')) {
          noClassesUtilities[`.no-${rule}`] = { [rule]: noClassValue }
        } else {
          noClassesUtilities[`.no-${ruleClass}`] = { [rule]: noClassValue }
        }
      } else if (side === 'x') {
        const noLeftRule = `${rule}-left`
        const noRightRule = `${rule}-right`

        noClassesUtilities[noClass] = {
          [noLeftRule]: noClassValue,
          [noRightRule]: noClassValue
        }
      } else if (side === 'y') {
        const noTopRule = `${rule}-top`
        const noBottomRule = `${rule}-bottom`

        noClassesUtilities[noClass] = {
          [noTopRule]: noClassValue,
          [noBottomRule]: noClassValue
        }
      } else {
        const noRule = `${rule}-${side}`

        noClassesUtilities[noClass] = {
          [noRule]: noClassValue
        }
      }
    })
  })

  addUtilities(noClassesUtilities)
}
