import React, { memo } from "react"
import cls from "classnames"
import { IconProps } from "~svg"
import { hover } from "@testing-library/user-event/dist/hover"

const LinkedinIcon: React.FC<IconProps> = props => {
  const { className, ...otherProps } = props

  return (
    <svg
      className={cls("fill-current", className)}
      width="535" height="512" viewBox="0 0 535 512" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3185_2)">
        <path
          d="M122.109 166.371V512.013H6.65553V166.371H122.109ZM129.449 59.6615L129.492 61.7522C129.492 77.7309 122.686 92.1095 111.815 102.179L111.772 102.2C105.368 108.039 97.8666 112.554 89.7009 115.483C81.5351 118.413 72.8677 119.699 64.2001 119.267H64.3499H63.6437C55.1753 119.667 46.713 118.36 38.7631 115.424C30.8133 112.488 23.5394 107.983 17.3769 102.179L17.3983 102.2C11.6761 96.6963 7.16926 90.0624 4.16603 82.7229C1.16281 75.3833 -0.270658 67.4996 -0.0426682 59.5762V59.6615L-0.0640682 58.1895C-0.0640682 41.9335 6.86953 27.2989 17.9333 17.0375L17.9761 17.0162C30.8277 5.34806 47.7987 -0.759344 65.1631 0.034861H65.0347C73.5425 -0.379664 82.0474 0.907352 90.0484 3.82009C98.0495 6.73283 105.385 11.2124 111.623 16.9949L111.58 16.9522C117.238 22.3744 121.738 28.8812 124.808 36.0815C127.879 43.2818 129.458 51.0268 129.449 58.8509V59.7469V59.7042V59.6615ZM537.376 313.955V512.013H422.286V327.16C423.456 306.875 418.419 286.715 407.841 269.347L408.098 269.795C403.121 262.807 396.413 257.224 388.627 253.589C380.841 249.955 372.243 248.394 363.672 249.059H363.843C350.45 248.582 337.324 252.878 326.821 261.176L326.949 261.091C317.252 268.882 309.661 278.965 304.865 290.424L304.693 290.915C301.763 300.084 300.466 309.694 300.863 319.31V319.16V512.056H185.774C186.244 419.285 186.48 344.063 186.48 286.392C186.48 228.721 186.366 194.31 186.137 183.16L185.795 166.414H300.884V216.632H300.178C304.758 209.272 309.487 202.894 314.687 196.92L314.516 197.112C320.529 190.52 326.992 184.568 333.947 179.235L334.29 178.979C343.194 172.167 353.297 167.074 364.078 163.96L364.72 163.811C377.212 160.178 390.163 158.353 403.176 158.392H404.867H404.781C422.72 157.639 440.61 160.78 457.211 167.598C473.813 174.416 488.73 184.749 500.931 197.88L500.996 197.966C525.277 224.362 537.418 263.018 537.418 313.934L537.376 313.955Z"
          fill="" />
      </g>
      <defs>
        <clipPath id="clip0_3185_2">
          <rect width="535" height="512" fill="white" />
        </clipPath>
      </defs>
    </svg>


  )
}

export default memo(LinkedinIcon)
