import React, { memo } from "react"
import cls from "classnames"
import { useIntl } from "~hooks"
import { useLocation } from "react-use"

const LanguageSwitcher = () => {
  const [intl, activeLocale, setLocale] = useIntl()
  const location = useLocation().pathname
  const isContactPage = location?.split("/").includes("contact")

  const choosenLanguage = isContactPage
    ? "text-text-gray-300"
    : "text-[#cb4405]"

  return (
    <div className="flex">
      <button
        onClick={() => setLocale("fr")}
        className={cls(
          "group font-bold text-2xl text-text-black  transition-colors",
          isContactPage ? "hover:text-tw-gray-300" : "hover:text-[#cb4405]",
          activeLocale === "fr" && choosenLanguage
        )}
      >
        FR
      </button>
      <span className={cls("group font-bold text-2xl text-text-black")}>/</span>
      <button
        onClick={() => setLocale("en")}
        className={cls(
          "group font-bold text-2xl text-text-black  transition-colors",
          isContactPage ? "hover:text-tw-gray-300" : "hover:text-[#cb4405]",

        activeLocale === "en" && choosenLanguage
        )}
      >
        EN
      </button>
    </div>
  )
}

export default memo(LanguageSwitcher)
