import loadable from "@loadable/component"

export const ApolloProvider = loadable(
  () => import("./ApolloProvider/ApolloProvider")
)
export const AlertProvider = loadable(
  () => import("./AlertProvider/AlertProvider")
)
export const IntlProvider = loadable(() => import("./IntlProvider"))

export * from "./ApolloProvider/ApolloProvider"
export * from "./AlertProvider/AlertProvider"
export * from "./IntlProvider"
